import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import Layout from './components/Layout';
import Home1 from './components/Home1';
import About from './components/About';
import NoPage from './components/NoPage';
import Jobs from './components/Jobs';
import Contact from './components/Contact';
import JoinAsExpert from './components/JoinAsExpert';
import JobsApply from './components/JobsApply';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsNConditions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import JoinIdeaHacka from './components/JoinIdeaHacka';
import Ideathon from './components/Ideathon';
import Hackathon from './components/Hackathon';
import GetApp from './components/GetApp';
import '../src/style/responsive.css'
import React from 'react';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home1 />} />
          {/* <Route path="home" element={<Home />} /> */}
          <Route path="about" element={<About />} />
          <Route path="jobs" element={<Jobs />} />
          <Route path="jobsapply" element={<JobsApply />} />
          <Route path="contact" element={<Contact />} />
          <Route path='joinasexpert' element={<JoinAsExpert />} />
          <Route path='privacy' element={<PrivacyPolicy />} />
          <Route path='termsandconditions' element={<TermsAndConditions />} />
          <Route path='download' element={<GetApp />} />
          <Route path='joinideathon' element={<JoinIdeaHacka />} />
          <Route path='ideathon' element={<Ideathon />} />
          <Route path='hackathon' element={<Hackathon />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
