import React from 'react'
import { NavLink, Outlet } from 'react-router-dom'
import '../style/navbar.css'
// import logo from '../assets/phnk_logo.jpg'
import logo from '../assets/icons/PHNK_BLACK.png'
import Footer from './Footer'
import { Menu, X } from 'lucide-react'

// import { Menu } from '@mui/material'
// import barcode from '../assets/barcode.png'

const Layout = () => {
    const [showHam, setShowHam] = React.useState(false)
    React.useEffect(() => {
        if (window.innerWidth < 500) {
            setShowHam(true)
        }
    }, [])

    const fun = () => {
        if(window.innerWidth<500){
            setShowHam(true)
        }
    }
    return (
        <>
            <div className='layout'>
                <div className='mobile-navbar'>
                    <NavLink to='/' >
                        <img style={{width: 100}} src={logo} alt='logo' className='' />
                    </NavLink>
                    <Menu
                    style={{
                        
                    }}
                    size={30}
                        onClick={() => setShowHam(!showHam)}
                    />
                </div>
                <div className={`${showHam ? 'none' : 'navbar'}`}>
                    <X
                        className={`'navbar-ele' ${showHam ? 'none' : 'desktop-none'}`}
                        style={{
                            marginLeft: '70vw',
                        }}
                        size={30}
                        onClick={() => setShowHam(!showHam)}
                    />
                    <NavLink className='navbar-ele logo-box' to='/' end>
                        <img style={{width: 100}} src={logo} alt='logo' className='logo' />
                    </NavLink>
                    <NavLink onClick={fun} className='navbar-ele' to='/' end>Home</NavLink>
                    <NavLink onClick={fun} className='navbar-ele' to='jobs'>Jobs</NavLink>
                    <NavLink onClick={fun} className='navbar-ele' to='about'>About</NavLink>
                    <NavLink onClick={fun} className='navbar-ele' to='contact'>Contact</NavLink>
                    {/* <NavLink onClick={fun} className='navbar-ele btn-1' to='joinasexpert'>Join As Expert</NavLink> */}
                </div>
                <Outlet />
            </div>
            <Footer />
        </>
    )
}

export default Layout