import React, { useEffect } from 'react';
import logo128 from '../../assets/logo128.png';
import { useInView } from "react-intersection-observer";

const MediaDisplay = ({ media, detailed }) => {
  const [media_type, setMedia_type] = React.useState("unsupported");
  const [url, setUrl] = React.useState(logo128);
  const [ref, inView] = useInView();
  const [play, setPlay] = React.useState(false);
  
  useEffect(() => {
    if (inView) {
      setPlay(true);
    } else {
      setPlay(false);
    }
  }, [inView])

  useEffect(() => {
    if (media !== null && media !== undefined && media.length > 0) {
      setMedia_type(media[0].media_type);
      setUrl(media[0].url);
    }
  }, [detailed, media, media_type, url]);

  const MediaFile = () => {
    if (media_type === 'audio') {
      return (
        <div style={{
          position: 'relative',
          display: 'flex'
        }}>
          <img className='media-file' src={media[0].backgroundAvatar} alt="Media" />
          <audio
            className='media-file'
            controls={detailed && play}
            autoPlay={detailed && play}
            src={url}
            style={{ position: 'absolute', left: '0', zIndex: '-1' }}
          />
        </div>
      );
    } else if (media_type === 'video') {
      return <video className='media-file' controls={detailed && play} autoPlay={detailed && play} src={url} />;
    } else if (media_type === 'image') {
      return <img className='media-file' src={url} alt="Media" />;
    } else {
      return <></>
      //return <img className='media-file unsupported-media' src={logo128} alt="Media" />;
    }
  }
  return (<div ref={ref}><MediaFile /></div>)
};

export default MediaDisplay;
