import React from 'react'
import '../style/footer.css'
import { Twitter, Youtube, Linkedin, Facebook, Instagram } from 'lucide-react'
import { Button } from '@mui/material'
import { useNavigate, NavLink } from 'react-router-dom'

const Footer = () => {
  const navigate = useNavigate()
  return (
    <div className='footer-box'>
      <Button
        variant='contained'
        className='get-app-btn'
        style={{
          backgroundColor: '#1982FC',
          borderRadius: '20px',
          padding: '0.5rem 2rem'
        }}
        onClick={() => navigate('/download')}
      >Get App</Button>
      <div className='footer'>
        <NavLink className='footer-ele' to='privacy'>Privacy</NavLink>
        <NavLink className='footer-ele' to='termsandconditions'>Terms And Conditions</NavLink>
        <NavLink to='contact' className='footer-ele'>Report An Issue</NavLink>
        <span className='footer-ele'>2002 All rights reserved Phnk Networks India</span>
        <span className='footer-ele social-media'>
          <a href='https://twitter.com/phnkit' target='_blank' rel='noreferrer'>
            <Twitter className='social-media-icon' />
          </a>
          <a href='https://www.youtube.com/@phnkit' target='_blank' rel='noreferrer'>
            <Youtube className='social-media-icon' />
          </a>
          <a href='https://linkedin.com/company/phnkit' target='_blank' rel='noreferrer'>
            <Linkedin className='social-media-icon' />
          </a>
          <a href='https://www.facebook.com/phnkit' target='_blank' rel='noreferrer'>
            <Facebook className='social-media-icon' />
          </a>
          <a href='https://www.instagram.com/phnk_it/' target='_blank' rel='noreferrer'>
            <Instagram className='social-media-icon' />
          </a>
        </span>
      </div>
    </div>
  )
}

export default Footer